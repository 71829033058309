import { relationTypeOptions } from "../../../periodic-reviews-webapp/src/constants";
import { PeriodicReviews } from "../../../periodic-reviews-webapp/src/types";

export const addNewRelation = (
  relationNumber: string
): PeriodicReviews.WebApp.FormData => {
  return [
    {
      id: `relation_${relationNumber}_header`,
      title: {
        en: `Relation ${relationNumber}`,
        es: `Relación ${relationNumber}`,
        nl: `Relatie ${relationNumber}`,
        fr: `Relation ${relationNumber}`,
      },
      type: "subheader",
    },
    {
      id: `relation_${relationNumber}_delete`,
      title: {
        en: `Relation delete`,
      },
      value: relationNumber,
      type: "relationDelete",
    },
    {
      id: `relation_${relationNumber}_relation_type_updated`,
      title: {
        en: `Relation type`,
        es: `Tipo de relación`,
        nl: `Relatietype`,
        fr: `Type de relation`,
      },
      type: "select",
      options: relationTypeOptions,
    },
  ];
};
