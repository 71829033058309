import localforage from "localforage";
import { createI18n } from "vue-i18n";
import { en } from "./assets/locales/en";
import { es } from "./assets/locales/es";
import { nl } from "./assets/locales/nl";
import { fr } from "./assets/locales/fr";
import { STORED_LANG_KEY } from "./constants";

const initializeI18n = async () => {
  const translations = {
    en,
    es,
    nl,
    fr,
  };

  const userLang = navigator.language.split("-")[0];
  const savedLang = await localforage.getItem(STORED_LANG_KEY);

  const i18n = createI18n({
    locale: (savedLang || userLang || "en") as string,
    messages: translations,
  });
  return i18n;
};

export default initializeI18n();
