import { PeriodicReviews } from "../../../periodic-reviews-webapp/src/types";

export const splitArrayBySeparator = (
  dataArray: PeriodicReviews.WebApp.FormData
) => {
  const resultArray: PeriodicReviews.WebApp.FormData[] = [];

  dataArray.forEach((element) => {
    const isSeparator = element.type === "separator";
    if (isSeparator) {
      resultArray.push([]);
    } else {
      resultArray[resultArray.length - 1].push(element);
    }
  });
  return resultArray;
};
