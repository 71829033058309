import localForage from "localforage";
import { Notify } from "notiflix";
import { PeriodicReviews } from "../../../periodic-reviews-webapp/src/types";
import { languageNames } from "../../../periodic-reviews-webapp/src/constants";

const translations: PeriodicReviews.WebApp.Translations = {
  en: "Changes saved locally",
  es: "Cambios guardados localmente",
  fr: "Modifications enregistrées localement",
  nl: "Wijzigingen lokaal opgeslagen",
} as const;

export const saveLocally = async (
  key: string,
  value: unknown,
  lang: keyof typeof languageNames,
  notify = false
) => {
  const stringifiedData = JSON.stringify(value);
  const parsedData = JSON.parse(stringifiedData);
  await localForage.setItem(key, parsedData);
  const message = translations[lang];
  if (!message) {
    throw new Error("No translation");
  }
  if (notify) Notify.success(message, { showOnlyTheLastOne: true });
};
