import localForage from "localforage";
import { STORED_LANG_KEY } from "../constants";
import { languageNames } from "../../../periodic-reviews-webapp/src/constants";

export const getSavedLang = async (): Promise<keyof typeof languageNames> => {
  const savedLang: keyof typeof languageNames | null =
    await localForage.getItem(STORED_LANG_KEY);
  if (!savedLang) {
    return "en";
  }
  return savedLang;
};
