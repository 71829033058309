import { enGB, es, fr, nl } from "date-fns/locale";
import { languageNames } from "../../../periodic-reviews-webapp/src/constants";

export const getDatePickerLocale = (lang: keyof typeof languageNames) => {
  // defaults
  const locale = {
    en: enGB,
    es,
    nl,
    fr,
  };

  return locale[lang as keyof typeof locale] || "";
};
