<template>
  <div class="top-bar">
    <div class="top-bar-inner">
      <div class="top-bar-logo">
        <img src="../assets/ebury-word-logo.svg" alt="" />
        <h2>{{ $t("formPage.title") }}</h2>
      </div>
      <language-picker />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LanguagePicker from "./LanguagePicker.vue";

export default defineComponent({
  components: { LanguagePicker },
});
</script>

<style>
.top-bar {
  display: flex;
  height: 100px;
  justify-content: center;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.top-bar-inner {
  display: flex;
  justify-content: space-between;
  width: var(--max-width);
  align-items: center;
}

.top-bar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-bar {
  font-size: 30px;
  margin: auto;
  margin-inline: 10px;
}

.top-bar-logo img {
  width: 90px;
  padding-right: 10px;
  border-right: 1px solid lightgrey;
}

.top-bar-logo h2 {
  padding-left: 10px;
  color: var(--primary-blue);
}
</style>
