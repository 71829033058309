import localforage from "localforage";
import {
  ACCOUNT_NUMBER_PARAMETER_KEY,
  STORED_ACCOUNT_NUMBER_KEY,
} from "../constants";

export const getCurrentAccountNumber = async () => {
  const currentUrl = new URL(window.location.href);
  const parameters = currentUrl.searchParams;
  const accountNumber = parameters.get(ACCOUNT_NUMBER_PARAMETER_KEY);
  if (accountNumber) {
    await localforage.setItem(STORED_ACCOUNT_NUMBER_KEY, accountNumber);
    return accountNumber;
  }
  const lastStoredAccountNumber = await localforage.getItem(
    STORED_ACCOUNT_NUMBER_KEY
  );
  console.log(lastStoredAccountNumber, accountNumber);
  return lastStoredAccountNumber as string | null;
};
