<template>
  <p class="login-message" v-if="noEmailMessage">
    {{ $t("loginPage.noEmailMessage") }}
  </p>
  <p class="login-message" v-if="emailSent">
    {{ $t("loginPage.loginMessage") }}
  </p>
</template>

<script lang="ts">
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import localForage from "localforage";
import Notiflix from "notiflix";
import { defineComponent } from "vue";
import { STORED_EMAIL_KEY, STORED_LANG_KEY } from "../constants";
import { firebaseApp } from "../firebase";
import {
  ERROR_MESSAGES,
  languageNames,
  languages,
} from "../../../periodic-reviews-webapp/src/constants";
import { getSavedLang } from "../utilities/getSavedLang";

export default defineComponent({
  data() {
    return {
      email: (this.$route.query.email as string) || "",
      emailSent: false,
      noEmailMessage: false,
    };
  },
  async created() {
    const firebaseInstance = firebaseApp.instance;
    const auth = getAuth(firebaseInstance);
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        this.$router.push("form");
        return;
      }
      const emailInUrl = this.$route.query.email as string;
      const langInUrl = this.$route.query.lang as string;
      const doesLangMatch = languages.includes(
        langInUrl as keyof typeof languageNames
      );
      const langToUse = doesLangMatch ? langInUrl : "en";
      await localForage.setItem(STORED_LANG_KEY, langToUse);
      this.$i18n.locale = langToUse;
      const doesEmailInUrlExist = !!emailInUrl;
      const shouldRedirectUser = !doesEmailInUrlExist;
      this.email = emailInUrl;
      if (shouldRedirectUser) {
        this.noEmailMessage = true;
        return;
      }
      this.handleSubmit();
    });
  },
  methods: {
    async handleSubmit() {
      const firebaseInstance = firebaseApp.instance;
      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true,
      };
      try {
        this.emailSent = true;
        const auth = getAuth(firebaseInstance);
        await sendSignInLinkToEmail(auth, this.email, actionCodeSettings);
        await localForage.setItem(STORED_EMAIL_KEY, this.email);
      } catch (error) {
        console.log(error);
        const lang = await getSavedLang();
        const errorTitle = ERROR_MESSAGES.SIGN_IN_FAILED[lang] as string;
        const errorMessage = ERROR_MESSAGES.SIGN_IN_FAILED_REFRESH[
          lang
        ] as string;
        Notiflix.Report.failure(errorTitle, errorMessage, "Ok", () => {
          window.location.reload();
        });
      }
    },
  },
});
</script>

<style>
.login-message {
  margin-top: 20px;
}
</style>
