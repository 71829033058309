import { VERSIONS } from "../../periodic-reviews-webapp/src/constants";

const componentNames = Object.keys(VERSIONS) as ("API" | "INTERFACE")[];
type ComponentName = (typeof componentNames)[number];

export const handleVersionChange = () => {
  componentNames.forEach((component: ComponentName) => {
    const currentVersion = VERSIONS[component];
    const storedVersion = Number(localStorage.getItem(`${component}-VERSION`));
    if (currentVersion !== storedVersion) {
      console.log(`Version mismatch for ${component}`, {
        currentVersion,
        storedVersion,
      });
      localStorage.setItem(`${component}-VERSION`, String(currentVersion));
    }
  });
};
