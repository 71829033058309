<template>
  <select class="lang-picker" v-model="lang" @change="handleLangChange">
    <option value="en">English</option>
    <option value="es">Español</option>
    <option value="nl">Nederlands</option>
    <option value="fr">Français</option>
  </select>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import localForage from "localforage";
import { STORED_LANG_KEY } from "../constants";

export default defineComponent({
  data(): { lang: string } {
    return {
      lang: this.$i18n.locale,
    };
  },
  methods: {
    async handleLangChange() {
      this.$i18n.locale = this.lang;
      await localForage.setItem(STORED_LANG_KEY, this.lang);
    },
  },
  watch: {
    "$i18n.locale": function (newLocale: string) {
      this.lang = newLocale;
    },
  },
});
</script>

<style>
.lang-picker {
  display: block;
  border: none;
  color: #555;
  padding: 10px 0;
  background-color: white;
}

.lang-picker:focus {
  outline: none;
}
</style>
