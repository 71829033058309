<template>
  <button
    :class="[
      'button',
      { 'primary-button': isPrimary, 'secondary-button': !isPrimary },
    ]"
    @click="handleClick"
  >
    <span v-if="showIcon">
      <img src="../assets/plus-icon.svg" alt="add icon" />
    </span>
    {{ buttonText }}
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    isPrimary: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleClick() {
      this.$emit("buttonClick");
    },
  },
});
</script>

<style>
.button {
  border-radius: 100px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 20px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary-button {
  width: 100%;
  background: var(--primary-blue);
  padding: 10px 20px;
  color: white;
}

.secondary-button {
  width: 200px;
  height: 40px;
  border: 2px solid var(--primary-blue);
  color: var(--primary-blue);
  background-color: white;
}

.button span {
  margin-top: 4px;
  padding-right: 5px;
}
</style>
