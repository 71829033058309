<template>
  <div class="loading-spinner">
    <img src="../assets/loading-spinner.svg" alt="loading spinner" />
    <p>{{ $t("formPage.waitMessage") }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style>
.loading-spinner {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: -100px;
}

.loading-spinner img {
  width: 32px;
  animation: spin 2s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
