import {
  addressProofDescription,
  idDocsDescription,
  yesOrNoOptions,
} from "../constants";
import { PeriodicReviews } from "../types";

export const getRelationDocRequirements = (
  relationNumber: string
): PeriodicReviews.WebApp.FormData => {
  return [
    {
      id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_fathers_name`,
      title: {
        en: `Father’s Name`,
        es: `Nombre del Padre`,
        nl: `Naam van de vader`,
        fr: `Le nom du père`,
      },
      type: "text",
      onlyForCountries: ["Greece"],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
          ["director"],
      },
    },

    //DOC REQUIREMENTS FOR AUTH CONTACT
    {
      id: `relation_${relationNumber}_relation_type_updated_auth_contact_valid_doc`,
      title: {
        en: `Please provide a valid ID document.`,
        es: `Proporcione un documento de identidad en vigor. `,
        nl: `Geef een geldig identiteitsbewijs op`,
        fr: `Veuillez fournir une pièce d'identité valide`,
      },
      type: "file",
      description: idDocsDescription,
      onlyForCountries: ["Belgium"],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["auth contact"],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_auth_contact_valid_certified_doc`,
      title: {
        en: `Please provide a valid and certified ID document.`,
        es: `Por favor, proporcione una copia certificada del Documento De Identidad. El documento de identidad debe estar en vigor.`,
        nl: `Graag ontvangen wij een geldig en gecertificeerd identiteitsbewijs.`,
        fr: `Veuillez fournir une pièce d'identité valide et certifiée.`,
      },
      type: "file",
      description: idDocsDescription,
      onlyForCountries: ["Canada", "Greece", "Portugal"],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["auth contact"],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_auth_contact_electronic_verification_method`,
      title: {
        en: `Would you like to complete the identity verification using an electronic verification method?`,
        es: `¿Preferiría utilizar un método de verificación de identidad electrónica?`,
        nl: `Wilt u liever een elektronische identiteitsverificatiemethode gebruiken?`,
        fr: `Préféreriez-vous utiliser une méthode de vérification d’identité électronique ?`,
      },
      type: "select",
      options: yesOrNoOptions,
      description: idDocsDescription,
      onlyForCountries: [
        "Czechia",
        "France",
        "Germany",
        "Italy",
        "Spain",
        "Poland",
        "Netherlands",
        "Luxembourg",
        "Bulgaria",
        "Romania",
      ],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["auth contact"],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_auth_contact_electronic_verification_method_message`,
      type: "paragraph",
      title: {
        en: "Please contact your relationship manager or KYC@ebury.com for them to give you a link to complete the electronic identity verification",
        es: "Póngase en contacto con su gerente de relaciones o kyc@ebury.com para que le den un enlace para completar la verificación de identidad electrónica",
        nl: "Neem contact op met uw relatiebeheerder of KYC@ebury.com zodat zij u een link kunnen geven waarmee u de elektronische identiteitsverificatie kunt voltooien",
        fr: "Veuillez contacter votre responsable relationnel ou KYC@ebury.com pour qu'il vous fournisse un lien permettant de compléter la vérification électronique de l'identité.",
      },
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["auth contact"],
        [`relation_${relationNumber}_relation_type_updated_auth_contact_electronic_verification_method`]:
          [true],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_auth_contact_certified_doc`,
      title: {
        en: `Please upload a certified ID document for each new authorised contact.`,
        es: `Sube un documento de identificación certificado para cada nuevo contacto autorizado.`,
        nl: `Upload voor elk nieuw geautoriseerd contact een gecertificeerd identiteitsbewijs.`,
        fr: `Veuillez télécharger une pièce d'identité certifiée pour chaque nouveau contact autorisé.`,
      },
      type: "file",
      description: idDocsDescription,
      onlyForCountries: [
        "Czechia",
        "France",
        "Germany",
        "Italy",
        "Spain",
        "Poland",
        "Netherlands",
        "Luxembourg",
        "Bulgaria",
        "Romania",
      ],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["auth contact"],
        [`relation_${relationNumber}_relation_type_updated_auth_contact_electronic_verification_method`]:
          [false],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_auth_contact_address_doc`,
      title: {
        en: `Please provide a proof of address document.`,
        es: `Proporcione un documento de prueba de dirección.`,
        fr: `Gelieve een bewijs van adres op te geven.`,
        nl: `Veuillez fournir un justificatif de domicile.`,
      },
      type: "file",
      onlyForCountries: [
        "Canada",
        "Czechia",
        "Germany",
        "Greece",
        "Netherlands",
        "Romania",
      ],
      description: addressProofDescription,
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["auth contact"],
      },
    },

    // DOC REQUIREMENTS FOR UBO
    {
      id: `relation_${relationNumber}_relation_type_updated_ubo_sharehold_structure_doc`,
      title: {
        en: `Please provide a document showing the shareholder structure, this must include all intermediary layers in the shareholding structure until reaching the UBO(s)`,
        es: `Por favor, proporcione un documento que refleje la estructura accionarial incluyendo todas las capas intermedias hasta llegar al titular real (UBO).`,
        fr: `Gelieve een document aan te leveren waaruit de aandeelhoudersstructuur blijkt.`,
        nl: `Veuillez fournir un document montrant la structure actionnariale, celui-ci doit inclure toutes les couches intermédiaires de la structure actionnariale jusqu'à atteindre le(s) UBO.`,
      },
      type: "file",
      onlyForCountries: ["Switzerland", "United Arab Emirates", "Greece"],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["ubo"],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_ubo_valid_doc`,
      title: {
        en: `Please provide a valid ID document for all new UBOs.`,
        es: `Por favor, proporcione una copia del Documento De Identidad de todos los nuevos titulares reales.`,
        fr: `Gelieve voor alle nieuwe UBO’s een geldig identiteitsbewijs op te geven.`,
        nl: `Veuillez fournir une pièce d’identité valide pour tous les nouveaux UBO.`,
      },
      type: "file",
      description: idDocsDescription,
      onlyForCountries: [
        "Belgium",
        "France",
        "Italy",
        "Spain",
        "Poland",
        "Luxembourg",
        "Netherlands",
        "Romania",
      ],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["ubo"],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_ubo_valid_certified_doc`,
      title: {
        en: `Please provide a valid and certified ID document for all new UBOs.`,
        es: `Por favor, proporcione una copia certificada del Documento De Identidad de todos los nuevos titulares reales.`,
        fr: `Voor alle nieuwe UBO’s dient u een geldig en gewaarmerkt identiteitsbewijs te verstrekken`,
        nl: `Veuillez fournir une pièce d’identité valide et certifiée pour tous les nouveaux UBO.`,
      },
      type: "file",
      description: {
        en: "Please provide a valid and certified ID document. ID documents should be in date and government issued and contain at least full name, photograph and date of birth. Certifications must be carried out by professionals authorised to do so in your country, be signed and contain the details of the certifier.",
        es: "Por favor, proporcione una copia certificada del Documento De Identidad. El documento de identidad debe estar en vigor e incluir, por lo menos, nombre completo, fotografía y fecha de nacimiento.  Las certificaciones deben llevarse a cabo por profesionales autorizados para ello siguiendo la normativa de su país, y deben estar firmados e incluir los detalles del certificador.",
        fr: "Graag ontvangen wij een geldig en gecertificeerd identiteitsbewijs. Identiteitsdocumenten moeten de datum en de door de overheid afgegeven datum hebben en ten minste de volledige naam, foto en geboortedatum bevatten. Certificeringen moeten worden uitgevoerd door professionals die daartoe in uw land geautoriseerd zijn, de documenten moeten ondertekend zijn en de gegevens van de certificeerder bevatten.",
        nl: "Veuillez fournir une pièce d'identité valide et certifiée. Les documents d'identité doivent être datés et délivrés par le Gouvernement et contenir au moins le nom complet, la photographie et la date de naissance. Les certifications doivent être réalisées par des professionnels habilités à le faire dans votre pays, être signées et contenir les coordonnées du certificateur.",
      },
      onlyForCountries: ["Greece", "Portugal"],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["ubo"],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_ubo_electronic_verification_method`,
      title: {
        en: `Would you like to complete the identity verification using an electronic verification method?`,
        es: `¿Le gustaría completar la verificación de identidad utilizando un método de verificación electrónica?`,
        fr: `Wilt u de identiteitsverificatie via een elektronische verificatiemethode voltooien?`,
        nl: `Souhaitez-vous effectuer la vérification d’identité à l’aide d’une méthode de vérification électronique?`,
      },
      type: "select",
      options: yesOrNoOptions,
      onlyForCountries: [
        "Switzerland",
        "Australia",
        "Canada",
        "Hong Kong",
        "United Arab Emirates",
        "Germany",
        "Bulgaria",
      ],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["ubo"],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_ubo_electronic_verification_method_message`,
      type: "paragraph",
      title: {
        en: "Please contact your relationship manager or KYC@ebury.com for them to give you a link to complete the electronic identity verification",
        es: "Póngase en contacto con su gerente de relaciones o kyc@ebury.com para que le den un enlace para completar la verificación de identidad electrónica",
        nl: "Neem contact op met uw relatiebeheerder of KYC@ebury.com zodat zij u een link kunnen geven waarmee u de elektronische identiteitsverificatie kunt voltooien",
        fr: "Veuillez contacter votre responsable relationnel ou KYC@ebury.com pour qu'il vous fournisse un lien permettant de compléter la vérification électronique de l'identité.",
      },
      onlyForCountries: [
        "Switzerland",
        "Australia",
        "Canada",
        "Hong Kong",
        "United Arab Emirates",
        "Germany",
        "Bulgaria",
      ],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["ubo"],
        [`relation_${relationNumber}_relation_type_updated_ubo_electronic_verification_method`]:
          [true],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_ubo_certified_doc`,
      title: {
        en: `Please upload a certified ID document.`,
        es: `Por favor, proporcione suba una copia certificada del Documento de Identidad.`,
        fr: `Upload een gecertificeerd identiteitsbewijs.`,
        nl: `Veuillez télécharger une pièce d'identité certifiée.`,
      },
      type: "file",
      onlyForCountries: [
        "Switzerland",
        "Australia",
        "Canada",
        "Hong Kong",
        "United Arab Emirates",
        "Germany",
        "Bulgaria",
      ],
      description: idDocsDescription,
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["ubo"],
        [`relation_${relationNumber}_relation_type_updated_ubo_electronic_verification_method`]:
          [false],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_ubo_address_doc`,
      title: {
        en: `Please provide a proof of address document dated within the last 3 month for all new UBOs.`,
        es: `Por favor, proporcione un justificante de domicilio emitido en los últimos 3 meses para todos los nuevos titulares reales (UBOs).`,
        nl: `UVoor alle nieuwe UBO's verzoeken wij u een adresbewijs mee te sturen dat gedateerd is binnen de afgelopen 3 maanden.`,
        fr: `Veuillez fournir un justificatif de domicile daté de moins de 3 mois pour tous les nouveaux UBO.`,
      },
      type: "file",
      onlyForCountries: ["Australia", "Greece"],
      description: addressProofDescription,
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["ubo"],
      },
    },

    //DOC REQUIREMENTS FOR DIRECTORS
    {
      id: `relation_${relationNumber}_relation_type_updated_directors_doc`,
      title: {
        en: `Please provide an official/third party document showing the current directors of the company`,
        es: `Por favor, proporcione un justificante de domicilio emitido en los últimos 3 meses para todos los nuevos titulares reales (UBOs).`,
        nl: `UVoor alle nieuwe UBO's verzoeken wij u een adresbewijs mee te sturen dat gedateerd is binnen de afgelopen 3 maanden.`,
        fr: `Veuillez fournir un justificatif de domicile daté de moins de 3 mois pour tous les nouveaux UBO.`,
      },
      type: "file",
      onlyForCountries: ["United Arab Emirates"],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["director"],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_director_electronic_verification_method`,
      title: {
        en: `Would you like to complete the identity verification using an electronic verification method?`,
        es: `¿Le gustaría completar la verificación de identidad utilizando un método de verificación electrónica?`,
        nl: `Wilt u de identiteitsverificatie via een elektronische verificatiemethode voltooien?`,
        fr: `Souhaitez-vous effectuer la vérification d’identité à l’aide d’une méthode de vérification électronique?`,
      },
      type: "select",
      options: yesOrNoOptions,
      onlyForCountries: ["Hong Kong"],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["director"],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_director_electronic_verification_method_message`,
      type: "paragraph",
      title: {
        en: "Please contact your relationship manager or KYC@ebury.com for them to give you a link to complete the electronic identity verification",
        es: "Póngase en contacto con su gerente de relaciones o kyc@ebury.com para que le den un enlace para completar la verificación de identidad electrónica",
        nl: "Neem contact op met uw relatiebeheerder of KYC@ebury.com zodat zij u een link kunnen geven waarmee u de elektronische identiteitsverificatie kunt voltooien",
        fr: "Veuillez contacter votre responsable relationnel ou KYC@ebury.com pour qu'il vous fournisse un lien permettant de compléter la vérification électronique de l'identité.",
      },
      onlyForCountries: ["Hong Kong"],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["director"],
        [`relation_${relationNumber}_relation_type_updated_director_electronic_verification_method`]:
          [true],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_director_certified_doc`,
      title: {
        en: `Please upload a certified ID document.`,
        es: `Por favor, proporcione suba una copia certificada del Documento de Identidad.`,
        nl: `Upload een gecertificeerd identiteitsbewijs.`,
        fr: `Veuillez télécharger une pièce d'identité certifiée.`,
      },
      type: "file",
      description: idDocsDescription,
      onlyForCountries: ["Hong Kong"],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["director"],
        [`relation_${relationNumber}_relation_type_updated_director_electronic_verification_method`]:
          [false],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_director_address_doc`,
      title: {
        en: `Please provide a proof of address document.`,
        es: `Proporcione un documento de prueba de dirección.`,
        nl: `Gelieve een bewijs van adres op te geven.`,
        fr: `Veuillez fournir un justificatif de domicile.`,
      },
      type: "file",
      description: addressProofDescription,
      onlyForCountries: ["United Kingdom", "United Arab Emirates"],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["director"],
      },
    },
    //DOC REQUIREMENTS FOR CORPORATE DIRECTOR DIRECTORS
    {
      id: `relation_${relationNumber}_relation_type_updated_corporate_director_directors_doc`,
      title: {
        en: `Please provide an official/third party document showing the current directors of the company`,
        es: `Por favor, proporcione un justificante de domicilio emitido en los últimos 3 meses para todos los nuevos titulares reales (UBOs).`,
        nl: `UVoor alle nieuwe UBO's verzoeken wij u een adresbewijs mee te sturen dat gedateerd is binnen de afgelopen 3 maanden.`,
        fr: `Veuillez fournir un justificatif de domicile daté de moins de 3 mois pour tous les nouveaux UBO.`,
      },
      type: "file",
      onlyForCountries: ["United Arab Emirates"],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
          ["director"],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_electronic_verification_method`,
      title: {
        en: `Would you like to complete the identity verification using an electronic verification method?`,
        es: `¿Preferiría utilizar un método de verificación de identidad electrónica?`,
        nl: `Wilt u liever een elektronische identiteitsverificatiemethode gebruiken?`,
        fr: `Préféreriez-vous utiliser une méthode de vérification d’identité électronique ?`,
      },
      type: "select",
      options: yesOrNoOptions,
      onlyForCountries: ["Hong Kong"],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
          ["director"],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_electronic_verification_method_message`,
      type: "paragraph",
      title: {
        en: "Please contact your relationship manager or KYC@ebury.com for them to give you a link to complete the electronic identity verification",
        es: "Póngase en contacto con su gerente de relaciones o kyc@ebury.com para que le den un enlace para completar la verificación de identidad electrónica",
        nl: "Neem contact op met uw relatiebeheerder of KYC@ebury.com zodat zij u een link kunnen geven waarmee u de elektronische identiteitsverificatie kunt voltooien",
        fr: "Veuillez contacter votre responsable relationnel ou KYC@ebury.com pour qu'il vous fournisse un lien permettant de compléter la vérification électronique de l'identité.",
      },
      onlyForCountries: ["Hong Kong"],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated`]: ["director"],
        [`relation_${relationNumber}_relation_type_updated_corporate_director_director_electronic_verification_method`]:
          [true],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_certified_doc`,
      title: {
        en: `Please upload a certified ID document.`,
        es: `Por favor, proporcione suba una copia certificada del Documento de Identidad.`,
        nl: `Upload een gecertificeerd identiteitsbewijs.`,
        fr: `Veuillez télécharger une pièce d'identité certifiée.`,
      },
      type: "file",
      description: idDocsDescription,
      onlyForCountries: ["Hong Kong"],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
          ["director"],
        [`relation_${relationNumber}_relation_type_updated_corporate_director_director_electronic_verification_method`]:
          [false],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_address_doc`,
      title: {
        en: `Please provide a proof of address document.`,
        es: `Proporcione un documento de prueba de dirección.`,
        nl: `Gelieve een bewijs van adres op te geven.`,
        fr: `Veuillez fournir un justificatif de domicile.`,
      },
      type: "file",
      description: addressProofDescription,
      onlyForCountries: ["United Kingdom", "United Arab Emirates"],
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
          ["director"],
      },
    },
    {
      id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_doc`,
      title: {
        en: `Please provide a document showing the full corporate director structure.`,
        es: `Proporcione un documento que muestre la estructura completa del director corporativo.`,
        nl: `Geef een document met de volledige bestuursstructuur.`,
        fr: `Veuillez fournir un document montrant la structure complète du directeur corporatif.`,
      },
      type: "file",
      renderConditions: {
        [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
          ["corporate director"],
      },
    },
  ];
};
