<template>
  <top-bar />
  <div class="content">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script lang="ts">
import TopBar from "./components/TopBar.vue";
import { defineComponent } from "vue";
import { getCurrentEmail } from "./utilities/get-current-email";
import { getCurrentAccountNumber } from "./utilities/get-current-account-number";

export default defineComponent({
  components: {
    TopBar,
  },
  data() {
    return {
      loading: true,
      isSignedIn: false,
    };
  },
  async beforeCreate() {
    console.log("Adding intercom to site");
    const email = (await getCurrentEmail()) as string;
    const accountNumber = await getCurrentAccountNumber();
    const intercomSettings = document.createElement("script");
    intercomSettings.innerHTML = `window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "xzh7ogzw",
        clientEmail: "${email}",
        accountNumber: "${accountNumber}",
        clientFrom: "Periodic Reviews"
      };`;

    const intercomFnc = document.createElement("script");
    intercomFnc.innerHTML = `(function () {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function () {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/xzh7ogzw";
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();`;

    const intercomShow = document.createElement("script");
    intercomShow.innerHTML = `window.Intercom('show');`;

    document.body.appendChild(intercomSettings);
    document.body.appendChild(intercomFnc);
    document.body.appendChild(intercomShow);
  },
});
</script>

<style>
@import "../node_modules/@fontsource/roboto/index.css";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary-blue: #00bef0;
  --dark-blue: #006ebd;
  --bg-blue: #e5f0f8;
  --light-grey: #dde2e3;
  --text-light-grey: #84959a;
  --text-grey: #556468;
  --max-width: 560px;
  --success-bg: #e6f1ee;
  --success-text: #0d7758;
}

#app {
  font-family: "Roboto", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  margin-inline: auto;
  font-family: Roboto;
}

p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: var(--text-grey);
}

h1 {
  margin: 0;
  font-size: 35px;
  font-weight: 400;
  color: var(--text-grey);
  margin-top: 24px;
  margin-bottom: 32px;
}

h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: var(--text-grey);
}

hr {
  border: 1px solid var(--light-grey);
  margin-block: 32px;
}

.logo {
  max-width: 240px;
}

.content {
  width: var(--max-width);
  margin: 40px auto;
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
}

label {
  font-family: Roboto;
  display: inline-block;
  margin-top: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: var(--text-grey);
  white-space: pre-wrap;
}

input,
textarea {
  display: block;
  color: #556468;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  border-radius: 5px;
  padding: 8px 12px;
  border: 1px solid var(--light-grey);
  width: 100%;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #00bef0;
}

input[type="checkbox"] {
  display: inline-block;
  cursor: pointer;
  height: 16px;
  width: 16px;
  border: 2px solid;
  margin: 0 10px;
  border-radius: 2px;
  position: relative;
  top: 2px;
}

input[type="file"] {
  border: none;
  padding-left: 0;
}

input[type="checkbox"]:hover {
  border-color: #0095bd;
}

input[type="checkbox"]:checked {
  background-color: #0095bd;
  border-color: #0095bd;
}

.asterisk {
  color: red;
}

.bold {
  font-weight: 700;
}
</style>
