<template>
  <div class="success-message">
    <img src="../assets/tick.svg" alt="check mark" />
    <p>{{ message }}</p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    message: { type: String, required: true },
  },
});
</script>

<style>
.success-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--success-bg);
  color: var(--success-text);
  padding: 24px;
  margin-bottom: 40px;
}

.success-message p {
  font-weight: 700;
}

.success-message img {
  width: 30px;
  margin-right: 16px;
}
</style>
