export const es = {
  languages: {
    english: "Inglés",
    spanish: "Español",
    dutch: "Holandés",
    french: "Francés",
  },
  loginPage: {
    title: "Acceder",
    email: "Correo Electronico",
    placeholder: "Introduce tu correo electrónico",
    buttonText: "Acceso",
    loginMessage:
      "Se ha enviado un correo electrónico a la dirección de correo electrónico proporcionada. Haga clic en el enlace del correo electrónico para autenticarse.",
    noEmailMessage:
      "Utilice el enlace del correo electrónico que se le envió para iniciar este proceso",
  },
  formPage: {
    title: "Actualización de información",
    submittedMessage:
      "¡El formulario ya ha sido enviado! Si desea cambiar la información presentada anteriormente, comuníquese con su gerente de relaciones",
    alreadySubmittedMessage: "¡El formulario ya ha sido enviado!",
    waitMessage: "Cargando datos, por favor espera...",
    asteriskMessage1: "Por favor, rellene todos los campos con",
    asteriskMessage2: "ya que son obligatorios.",
    savedLocallyMessage:
      "Todos los cambios se guardarán localmente en su dispositivo.",
    accessFormAgain: "Puede acceder al formulario nuevamente usando el enlace",
    openPRs: "Revisiones periódicas abiertas",
  },
};
