<template>
  <div class="informational">
    <img
      src="../assets/information-icon.svg"
      alt="information icon"
      class="information-icon"
    />
    <div>
      <p class="bold">{{ $t("formPage.savedLocallyMessage") }}</p>
      <p>{{ $t("formPage.accessFormAgain") }}</p>
    </div>
  </div>
</template>

<style>
.informational {
  display: flex;
  flex-direction: row;
  gap: 20px;
  background-color: var(--bg-blue);
  padding: 20px;
  color: var(--dark-blue);
  margin-bottom: 40px;
  border-radius: 5px;
}

.information-icon {
  width: 24px;
  height: 24px;
}
</style>
