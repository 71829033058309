<template>
  <div class="pr-selector">
    <label>{{ $t("formPage.openPRs") }}:</label>
    <select v-model="currentSelection" @change="handleChange" class="select">
      <option
        v-for="account in accounts"
        :key="account.accountNumber"
        :value="account.accountNumber"
      >
        {{ account.companyName }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import localForage from "localforage";
import { STORED_ACCOUNT_NUMBER_KEY } from "../constants";
import { PeriodicReviews } from "../../../periodic-reviews-webapp/src/types";

export default defineComponent({
  props: {
    accounts: {
      type: Array as PropType<PeriodicReviews.WebApp.Accounts>,
      required: true,
    },
  },
  data() {
    return {
      currentSelection: "",
    };
  },
  async mounted() {
    const storedAccountNumber: string | null = await localForage.getItem(
      STORED_ACCOUNT_NUMBER_KEY
    );
    if (storedAccountNumber) {
      this.currentSelection = storedAccountNumber;
    }
  },
  methods: {
    async handleChange(event: Event) {
      const inputElement = event.target as HTMLInputElement;
      const newAccountNumber = inputElement.value;
      this.currentSelection = newAccountNumber;
      await localForage.setItem(STORED_ACCOUNT_NUMBER_KEY, newAccountNumber);
      const queryParams = { ...this.$route.query };
      queryParams.accountNumber = newAccountNumber;
      this.$router.replace({
        ...this.$router.currentRoute,
        query: queryParams,
      });
    },
  },
});
</script>

<style>
.pr-selector {
  display: block;
  margin-bottom: 32px;
}

.pr-selector label {
  margin-right: 5px;
}
</style>
