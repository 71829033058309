import { callApi } from "./call-api";

export const errorMonitor = () => {
  window.onerror = async (message, source, lineno, colno, error) => {
    const errorMessage = [message, source, lineno, colno, error]
      .filter(Boolean)
      .join("\n");
    await notifyError(errorMessage);
  };

  window.onunhandledrejection = async (event) => {
    const errorMessage = `Unhandled Rejection at: Promise ${event.promise} reason: ${event.reason}`;
    await notifyError(errorMessage);
  };
};
const notifyError = async (message: string) => {
  const messageWithContext = `
  Error in form at url: ${window.location.href} .
  Error message: ${message}
  `;
  const data = {
    message: messageWithContext,
  };
  await callApi({
    action: "notify_error",
    data,
  });
};
