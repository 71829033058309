import {
  getRelationDivider,
  nbbCountries,
  secondaryRelationTypeOptions,
} from "../constants";
import { PeriodicReviews } from "../types";
import { getRelationDocRequirements } from "../utils/getRelationDocRequirements";

export const addRelationFields = (options: {
  relationNumber: string;
  country: string;
  relationType: string;
  didTheRelationChangeId?: string;
  isExistingRelation?: boolean;
}) => {
  const {
    relationNumber,
    country,
    relationType,
    didTheRelationChangeId,
    isExistingRelation,
  } = options;
  // We are doing it like this so that we filter out the unnecessary questions.
  // if it is a director and the country is in europe, then
  // only ask the defaults (name, nationality, dob, address)
  const isDirector = relationType === "director";
  const isInEurope = nbbCountries.includes(country);
  const askDefaultQuestionsOnly = isDirector && isInEurope;
  const countryToUse = askDefaultQuestionsOnly ? "Not in list" : country;

  const allFields = getAllFields(relationNumber);
  let filteredFieldsByCountry = allFields.filter(
    (field) =>
      !field.onlyForCountries || field.onlyForCountries.includes(countryToUse)
  );

  if (isExistingRelation) {
    filteredFieldsByCountry = filteredFieldsByCountry.filter((field) => {
      const shouldInclude =
        !field.id.includes("dob") &&
        !field.id.includes("gender") &&
        !field.id.includes("birth_place") &&
        !field.id.includes("_info");
      return shouldInclude;
    });
  }

  if (didTheRelationChangeId) {
    filteredFieldsByCountry = filteredFieldsByCountry.map((field) => {
      return {
        ...field,
        renderConditions: {
          ...field.renderConditions,
          [`${didTheRelationChangeId}`]: ["changed"],
        },
      };
    });
  }

  return filteredFieldsByCountry;
};

const getAllFields = (
  relationNumber: string
): PeriodicReviews.WebApp.ElementData[] => [
  // Corporate Directors
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_legal_entity_name`,
    title: {
      en: "Legal Entity Name",
      es: "Nombre de entidad legal",
      nl: "Naam rechtspersoon",
      fr: "Nom de l'entité juridique",
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "corporate director",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_country_of_incorporation`,
    title: {
      en: "Country of incorporation",
      es: "País de incorporación",
      nl: "land van oprichting",
      fr: "Pays de constitution",
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "corporate director",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`,
    title: {
      en: `Provide the director or corporate director information that manages the corporate director that has just been added`,
      es: `Proporcione información sobre el director o director corporativo que administre al director corporativo que acaba de agregar.`,
      nl: `Geef informatie over de directeur of bedrijfsdirecteur die de zojuist toegevoegde bedrijfsdirecteur beheert`,
      fr: `Fournissez les informations du directeur corporatif gérant le directeur corporatif qui vient d'être ajouté.`,
    },
    type: "select",
    options: secondaryRelationTypeOptions,
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "corporate director",
      ],
    },
  },
  // relation type info
  {
    id: `relation_${relationNumber}_relation_auth_contact_info`,
    title: {
      en: "Please provide details for the new authorised contact",
      es: "Proporcione detalles para el nuevo contacto autorizado",
      nl: "Gelieve de gegevens van de nieuwe geautoriseerde contactpersoon op te geven",
      fr: "Veuillez fournir les détails du nouveau contact autorisé",
    },
    type: "paragraph",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: ["auth contact"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_ubo_info`,
    title: {
      en: "Please state who the new UBO(s) of the company are and provide their personal details.",
      es: "Por favor, proporcione quiénes son los nuevos titulares reales (UBOs) de la empresa y sus datos personales.",
      nl: "Vermeld hierbij wie de nieuwe UBO('s) van de onderneming zijn en geef hun persoonsgegevens door.",
      fr: "Veuillez indiquer qui sont les nouveaux UBO de l'entreprise et fournir leurs coordonnées.",
    },
    type: "paragraph",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: ["ubo"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_director_info`,
    title: {
      en: "Please state who the current director(s) of the company are",
      es: "Por favor, confirme quiénes son los admisnitradores actuales de la empresa.",
      nl: "Gelieve te vermelden wie de huidige directeur(en) van de vennootschap zijn.",
      fr: "Veuillez indiquer qui sont les directeurs actuels de la société.",
    },
    type: "paragraph",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_signatory_info`,
    title: {
      en: "Please provide the updated details for the signatory ",
      es: "Por favor, proporcione los datos personales actualizados del firmante.",
      nl: "Gelieve de bijgewerkte gegevens van de ondertekenaar op te geven.",
      fr: "Veuillez fournir les détails mis à jour pour le signataire.",
    },
    type: "paragraph",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: ["signatory"],
    },
  },
  //relation data
  {
    id: `relation_${relationNumber}_relation_type_updated_first_name`,
    title: {
      en: `First Name`,
      es: `Nombre`,
      nl: `Naam`,
      fr: `Prénom`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_middle_name`,
    title: {
      en: `Middle Name (optional)`,
      es: `Nombre compuesto (opcional)`,
      nl: `Midden-naam (optioneel)`,
      fr: `Deuxième prénom (optionnel)`,
    },
    type: "text",
    required: false,
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_surname`,
    title: {
      en: `Surname`,
      es: `Apellido`,
      nl: `Achternaam`,
      fr: `Nom de famille`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_dob`,
    title: {
      en: `Date of Birth`,
      es: `Fecha de nacimiento`,
      nl: `Geboortedatum`,
      fr: `Date de naissance`,
    },
    type: "date",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_birth_place`,
    title: {
      en: `Place of Birth`,
      es: `Lugar de nacimiento`,
      nl: `Geboorteplaats`,
      fr: `Lieu de naissance`,
    },
    onlyForCountries: [
      "Belgium",
      "Bulgaria",
      "Czech Republic",
      "France",
      "Germany",
      "Greece",
      "Italy",
      "Netherlands",
      "Poland",
      "Portugal",
      "Romania",
      "Luxembourg",
    ],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_nationality`,
    title: {
      en: `Nationality`,
      es: `Nacionalidad`,
      nl: `Nationaliteit`,
      fr: `Nationalité`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_gender`,
    title: {
      en: `Gender`,
      es: `Género`,
      nl: `Geslacht`,
      fr: `Genre`,
    },
    onlyForCountries: [
      "Belgium",
      "France",
      "Germany",
      "Greece",
      "Italy",
      "Netherlands",
      "Poland",
      "Luxembourg",
    ],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_street_name`,
    title: {
      en: `Street name and number`,
      es: `Nombre y número de la calle`,
      nl: `Straatnaam en huisnummer`,
      fr: `Nom et numéro de rue`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_city`,
    title: {
      en: `City`,
      es: `Ciudad`,
      nl: `Stad`,
      fr: `Ville`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_county_state_province`,
    title: {
      en: `County/State/Province`,
      es: `Condado/estado/provincia`,
      nl: `Provincie`,
      fr: `Département/État/Province`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_country`,
    title: {
      en: `Country`,
      es: `País`,
      nl: `Land`,
      fr: `Pays`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_postal_code`,
    title: {
      en: `Postal Code`,
      es: `Código Postal`,
      nl: `Postcode`,
      fr: `Code postal`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_phone_number`,
    title: {
      en: `Phone Number (landline or mobile)`,
      es: `Número de teléfono (teléfono fijo o móvil)`,
      nl: `Telefoonnummer (vaste of mobiel)`,
      fr: `Numéro de téléphone (ligne fixe ou mobile)`,
    },
    onlyForCountries: ["Greece"],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_doc_type`,
    title: {
      en: `Document type`,
      es: `Tipo de Documento`,
      nl: `Type document`,
      fr: `Type de document`,
    },
    type: "text",
    onlyForCountries: [
      "Belgium",
      "Bulgaria",
      "Czech Republic",
      "France",
      "Germany",
      "Greece",
      "Italy",
      "Netherlands",
      "Poland",
      "Portugal",
      "Romania",
      "Spain",
      "Luxembourg",
    ],
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_doc_number`,
    title: {
      en: `Document Number`,
      es: `Número del Documento`,
      nl: `Document Nummer`,
      fr: `numéro de document`,
    },
    type: "text",
    onlyForCountries: [
      "Belgium",
      "Bulgaria",
      "Czech Republic",
      "France",
      "Germany",
      "Greece",
      "Italy",
      "Poland",
      "Portugal",
      "Romania",
      "Spain",
      "Luxembourg",
    ],
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_issue_date`,
    title: {
      en: `Date of Issue`,
      es: `Fecha de emisión`,
      nl: `Uitgavedatum`,
      fr: `Date d'émission`,
    },
    onlyForCountries: [
      "Belgium",
      "Bulgaria",
      "Czech Republic",
      "France",
      "Germany",
      "Greece",
      "Italy",
      "Netherlands",
      "Poland",
      "Portugal",
      "Romania",
      "Luxembourg",
    ],
    type: "date",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_expiry_date`,
    title: {
      en: `Date of Expiry`,
      es: `Fecha de caducidad`,
      nl: `vervaldatum`,
      fr: `Date d'émission`,
    },
    type: "date",
    onlyForCountries: ["Netherlands"],
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_issue_country`,
    title: {
      en: `Country of Issue`,
      es: `País de Emisión`,
      nl: `Land van afgifte`,
      fr: `Pays d'émission`,
    },
    type: "text",
    onlyForCountries: [
      "Belgium",
      "Bulgaria",
      "Czech Republic",
      "France",
      "Germany",
      "Greece",
      "Italy",
      "Netherlands",
      "Poland",
      "Portugal",
      "Romania",
      "Spain",
      "Luxembourg",
    ],
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_issue_place`,
    title: {
      en: `Place of Issue`,
      es: `Lugar de emisión`,
      nl: `Plaats van afgifte`,
      fr: `Lieu d'émission`,
    },
    onlyForCountries: ["France", "Greece", "Italy", "Netherlands"],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_tax_number`,
    title: {
      en: `Tax Identification Number`,
      es: `Numero de identificación fiscal`,
      nl: `BTW-nummer`,
      fr: `Numéro d'identification fiscale`,
    },
    onlyForCountries: [
      "Belgium",
      "Greece",
      "Italy",
      "Netherlands",
      "Poland",
      "Portugal",
      "Luxembourg",
    ],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_occupation`,
    title: {
      en: `Occupation`,
      es: `Ocupación`,
      nl: `Beroep`,
      fr: `Profession`,
    },
    onlyForCountries: ["Greece"],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_occupation_street_name`,
    title: {
      en: `Street name and number`,
      es: `Nombre y número de la calle`,
      nl: `Straatnaam en huisnummer`,
      fr: `Nom et numéro de rue`,
    },
    onlyForCountries: ["Greece"],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_occupation_city`,
    title: {
      en: `City`,
      es: `Ciudad`,
      nl: `Stad`,
      fr: `Ville`,
    },
    onlyForCountries: ["Greece"],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_occupation_county_province_state`,
    title: {
      en: `County/State/Province`,
      es: `Condado/estado/provincia`,
      nl: `Provincie`,
      fr: `Département/État/Province`,
    },
    onlyForCountries: ["Greece"],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_occupation_country`,
    title: {
      en: `Country`,
      es: `País`,
      nl: `Land`,
      fr: `Pays`,
    },
    onlyForCountries: ["Greece"],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_occupation_postal_code`,
    title: {
      en: `Postal Code`,
      es: `Código Postal`,
      nl: `Postcode`,
      fr: `Code postal`,
    },
    onlyForCountries: ["Greece"],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_fathers_name`,
    title: {
      en: `Father’s Name`,
      es: `Nombre del Padre`,
      nl: `Naam van de vader`,
      fr: `Le nom du père`,
    },
    type: "text",
    onlyForCountries: ["Greece"],
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated`]: [
        "auth contact",
        "ubo",
        "director",
        "signatory",
      ],
    },
  },

  //relation data for corporate director director
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_first_name`,
    title: {
      en: `First Name`,
      es: `Nombre`,
      nl: `Naam`,
      fr: `Prénom`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_middle_name`,
    title: {
      en: `Middle Name (optional)`,
      es: `Nombre compuesto (opcional)`,
      nl: `Midden-naam (optioneel)`,
      fr: `Deuxième prénom (optionnel)`,
    },
    type: "text",
    required: false,
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_surname`,
    title: {
      en: `Surname`,
      es: `Apellido`,
      nl: `Achternaam`,
      fr: `Nom de famille`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_dob`,
    title: {
      en: `Date of Birth`,
      es: `Fecha de nacimiento`,
      nl: `Geboortedatum`,
      fr: `Date de naissance`,
    },
    type: "date",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_birth_place`,
    title: {
      en: `Place of Birth`,
      es: `Lugar de nacimiento`,
      nl: `Geboorteplaats`,
      fr: `Lieu de naissance`,
    },
    onlyForCountries: [
      "Belgium",
      "Bulgaria",
      "France",
      "Germany",
      "Greece",
      "Italy",
      "Netherlands",
      "Poland",
      "Portugal",
      "Romania",
    ],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_nationality`,
    title: {
      en: `Nationality`,
      es: `Nacionalidad`,
      nl: `Nationaliteit`,
      fr: `Nationalité`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_corporate_director_director_updated_gender`,
    title: {
      en: `Gender`,
      es: `Género`,
      nl: `Geslacht`,
      fr: `Genre`,
    },
    onlyForCountries: [
      "Belgium",
      "France",
      "Germany",
      "Greece",
      "Italy",
      "Netherlands",
      "Poland",
      "Luxembourg",
    ],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_street_name`,
    title: {
      en: `Street name and number`,
      es: `Nombre y número de la calle`,
      nl: `Straatnaam en huisnummer`,
      fr: `Nom et numéro de rue`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_city`,
    title: {
      en: `City`,
      es: `Ciudad`,
      nl: `Stad`,
      fr: `Ville`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_county_state_province`,
    title: {
      en: `County/State/Province`,
      es: `Condado/estado/provincia`,
      nl: `Provincie`,
      fr: `Département/État/Province`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_country`,
    title: {
      en: `Country`,
      es: `País`,
      nl: `Land`,
      fr: `Pays`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_postal_code`,
    title: {
      en: `Postal Code`,
      es: `Código Postal`,
      nl: `Postcode`,
      fr: `Code postal`,
    },
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_phone_number`,
    title: {
      en: `Phone Number (landline or mobile)`,
      es: `Número de teléfono (teléfono fijo o móvil)`,
      nl: `Telefoonnummer (vaste of mobiel)`,
      fr: `Numéro de téléphone (ligne fixe ou mobile)`,
    },
    onlyForCountries: ["Greece"],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_doc_type`,
    title: {
      en: `Document type`,
      es: `Tipo de Documento`,
      nl: `Type document`,
      fr: `Type de document`,
    },
    type: "text",
    onlyForCountries: [
      "Belgium",
      "Bulgaria",
      "Czech Republic",
      "France",
      "Germany",
      "Greece",
      "Italy",
      "Netherlands",
      "Poland",
      "Portugal",
      "Romania",
      "Spain",
      "Luxembourg",
    ],
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_doc_number`,
    title: {
      en: `Document Number`,
      es: `Número del Documento`,
      nl: `Document Nummer`,
      fr: `numéro de document`,
    },
    type: "text",
    onlyForCountries: [
      "Belgium",
      "Bulgaria",
      "Czech Republic",
      "France",
      "Germany",
      "Greece",
      "Italy",
      "Poland",
      "Portugal",
      "Romania",
      "Spain",
      "Luxembourg",
    ],
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_issue_date`,
    title: {
      en: `Date of Issue`,
      es: `Fecha de emisión`,
      nl: `Uitgavedatum`,
      fr: `Date d'émission`,
    },
    onlyForCountries: [
      "Belgium",
      "Bulgaria",
      "Czech Republic",
      "France",
      "Germany",
      "Greece",
      "Italy",
      "Netherlands",
      "Poland",
      "Portugal",
      "Romania",
      "Luxembourg",
    ],
    type: "date",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_expiry_date`,
    title: {
      en: `Date of Expiry`,
      es: `Fecha de caducidad`,
      nl: `vervaldatum`,
      fr: `Date d'émission`,
    },
    type: "date",
    onlyForCountries: ["Netherlands", "Portugal"],
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_issue_country`,
    title: {
      en: `Country of Issue`,
      es: `País de Emisión`,
      nl: `Land van afgifte`,
      fr: `Pays d'émission`,
    },
    type: "text",
    onlyForCountries: [
      "Belgium",
      "Bulgaria",
      "Czech Republic",
      "France",
      "Germany",
      "Greece",
      "Italy",
      "Netherlands",
      "Poland",
      "Portugal",
      "Romania",
      "Spain",
      "Luxembourg",
    ],
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_issue_place`,
    title: {
      en: `Place of Issue`,
      es: `Lugar de emisión`,
      nl: `Plaats van afgifte`,
      fr: `Lieu d'émission`,
    },
    onlyForCountries: ["France", "Greece", "Italy", "Netherlands"],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_tax_number`,
    title: {
      en: `Tax Identification Number`,
      es: `Numero de identificación fiscal`,
      nl: `BTW-nummer`,
      fr: `Numéro d'identification fiscale`,
    },
    onlyForCountries: [
      "Belgium",
      "Greece",
      "Italy",
      "Netherlands",
      "Poland",
      "Portugal",
      "Luxembourg",
    ],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_occupation`,
    title: {
      en: `Occupation`,
      es: `Ocupación`,
      nl: `Beroep`,
      fr: `Profession`,
    },
    onlyForCountries: ["Greece"],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_occupation_street_name`,
    title: {
      en: `Street name and number`,
      es: `Nombre y número de la calle`,
      nl: `Straatnaam en huisnummer`,
      fr: `Nom et numéro de rue`,
    },
    onlyForCountries: ["Greece"],
    type: "text",
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_occupation_city`,
    title: {
      en: `City`,
      es: `Ciudad`,
      nl: `Stad`,
      fr: `Ville`,
    },
    onlyForCountries: ["Greece"],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_occupation_county_province_state`,
    title: {
      en: `County/State/Province`,
      es: `Condado/estado/provincia`,
      nl: `Provincie`,
      fr: `Département/État/Province`,
    },
    onlyForCountries: ["Greece"],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_occupation_country`,
    title: {
      en: `Country`,
      es: `País`,
      nl: `Land`,
      fr: `Pays`,
    },
    onlyForCountries: ["Greece"],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  {
    id: `relation_${relationNumber}_relation_type_updated_corporate_director_director_occupation_postal_code`,
    title: {
      en: `Postal Code`,
      es: `Código Postal`,
      nl: `Postcode`,
      fr: `Code postal`,
    },
    onlyForCountries: ["Greece"],
    type: "text",
    renderConditions: {
      [`relation_${relationNumber}_relation_type_updated_corporate_director_secondary_type_update`]:
        ["director"],
    },
  },
  ...getRelationDocRequirements(relationNumber),
  getRelationDivider(relationNumber),
];
